export enum AccountCustomData {
  jobTitle = 'jobTitle',
  logoId = 'logoId',
  industry = 'industry',
  size = 'size',
  locationAddress = 'locationAddress',
  locationLng = 'locationLng',
  locationLat = 'locationLat',
  type = 'type',
}

export enum AccountType {
  PlaybookAdmin = 'PlaybookAdmin',
  ServiceProvider = 'ServiceProvider',
  Organisation = 'Organisation',
}

export type Account = {
  id: string;
  parentId: string;
  name: string;
  customData?: Record<AccountCustomData, string>;
};
